const initState = {
    articles: [],
    article: {},
    isLoading: false
}

const articleReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SHOW_ALL_ARTICLES':
            return {
                ...state,
                articles: action.payload,
                isLoading: false
            }
        case 'SHOW_ARTICLE':
            return {
                ...state,
                article: action.payload,
                isLoading: false
            }
        case 'CHANGE_LOADING_STATUS':
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}

export default articleReducer