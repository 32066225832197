import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import NRICorner from "./Views/Article/NRICorner";
const BasicsOfINvestments = lazy(() =>
  import("./Views/Article/BasicsOfINvestments")
);
const MutualFunds = lazy(() => import("./Views/Article/MutualFunds"));
const Insurance = lazy(() => import("./Views/Article/Insurance"));
const SovereignGoldBonds = lazy(() =>
  import("./Views/Article/SovereignGoldBonds")
);
const General = lazy(() => import("./Views/Article/General"));
const Navbar = lazy(() => import("./Navbar"));
const About = lazy(() => import("./Views/About/About"));
const Articles = lazy(() => import("./Views/Article/Articles"));
const FinancialCalculator = lazy(() => import("./Views/FinancialCalculator"));
const Noticeboard = lazy(() => import("./Views/Noticeboard/typesNotices"));
const Sgb = lazy(() => import("./Views/Noticeboard/sgb"));
const smallSavings = lazy(() => import("./Views/Noticeboard/smallsavings"));
const bajajFin = lazy(() => import("./Views/Noticeboard/bajajfin"));
const hdfc = lazy(() => import("./Views/Noticeboard/hdfc"));
const bank = lazy(() => import("./Views/Noticeboard/bank"));
const generalNotice = lazy(() => import("./Views/Noticeboard"));
const singleNotice = lazy(() => import("./Views/Noticeboard/singleNotice"));
const Products = lazy(() => import("./Views/Products/Products"));
const ZeroProducts = lazy(() => import("./Views/Products/zero"));
const ModProducts = lazy(() => import("./Views/Products/mod"));
const HighProducts = lazy(() => import("./Views/Products/high"));
const SubmitQuery = lazy(() => import("./Views/SubmitQuery"));
const Testimonials = lazy(() => import("./Views/Testimonials/Testimonials"));
const Article = lazy(() => import("./Views/Article/Article"));
const Product = lazy(() => import("./Views/Products/Product"));
const Login = lazy(() => import("./Views/Auth/Login"));
const AdminLanding = lazy(() => import("./Views/Admin/AdminLanding"));
const AddProduct = lazy(() => import("./Views/Admin/Products/AddProduct"));
const ViewProducts = lazy(() => import("./Views/Admin/Products/ViewProducts"));
const ViewQuery = lazy(() => import("./Views/Admin/Query"));
const AddArticle = lazy(() => import("./Views/Admin/Articles/AddArticle"));
const ViewArticles = lazy(() => import("./Views/Admin/Articles/ViewArticles"));
const AddTestimony = lazy(() => import("./Views/Testimonials/AddTestimony"));
const Loading = lazy(() => import("./loading"));
const LandingScreen = lazy(() => import("./LandingScreen"));
const ProductTypes = lazy(() => import("./Views/Products/ProductTypes"));
const AddNoticeboard = lazy(() =>
  import("./Views/Admin/Noticeboard/AddNoticeboard")
);
const Footer = lazy(() => import("./Footer"));
const ApproveTestimony = lazy(() =>
  import("./Views/Admin/Testimonials/ApproveTestimony")
);
const Signnin = lazy(() => import("./Views/Auth/Signin"));

const Main = () => (
  <Suspense
    fallback={
      <div
        style={{
          width: "100%",
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" />
      </div>
    }
  >
    <div style={{ width: "100%" }}>
      <Navbar />
      <Switch>
        <Route exact path="/" component={LandingScreen} />
        <Route path="/about" component={About} />
        <Route exact path="/articles" component={Articles} />
        <Route
          exact
          path="/BasicsOfInvestments"
          component={BasicsOfINvestments}
        />
        <Route exact path="/MutualFunds" component={MutualFunds} />
        <Route path="/Insurance" component={Insurance} />
        <Route path="/SovereignGoldBonds" component={SovereignGoldBonds} />
        <Route path="/General" component={General} />
        <Route path="/NRICorner" component={NRICorner} />
        <Route exact path="/article/:id" component={Article} />
        <Route path="/financialcalculator" component={FinancialCalculator} />
        <Route exact path="/noticeboard" component={Noticeboard} />
        <Route path="/notice/sgb" component={Sgb} />
        <Route path="/notice/smallsavings" component={smallSavings} />
        <Route path="/notice/bajajfin" component={bajajFin} />
        <Route path="/notice/hdfc" component={hdfc} />
        <Route path="/notice/bank" component={bank} />
        <Route path="/notice/general" component={generalNotice} />
        <Route path="/noticeboard/:id" component={singleNotice} />
        <Route path="/producttypes" component={ProductTypes} />
        <Route exact path="/products" component={Products} />
        <Route path="/products/:id" component={Product} />
        <Route exact path="/investment/zero" component={ZeroProducts} />
        <Route exact path="/investment/moderate" component={ModProducts} />
        <Route exact path="/investment/high" component={HighProducts} />
        <Route path="/submitquery" component={SubmitQuery} />
        <Route path="/testimonials" component={Testimonials} />
        <Route path="/login" component={Login} />
        <Route exact path="/adminlanding" component={AdminLanding} />
        <Route path="/adminlanding/addproduct" component={AddProduct} />
        <Route path="/adminlanding/viewproducts" component={ViewProducts} />
        <Route path="/adminlanding/viewqueries" component={ViewQuery} />
        <Route path="/adminlanding/viewarticles" component={ViewArticles} />
        <Route path="/adminlanding/addarticle" component={AddArticle} />
        <Route path="/addtestimony" component={AddTestimony} />
        <Route path="/adminlanding/addnoticeboard" component={AddNoticeboard} />
        <Route
          path="/adminlanding/approvetestimony"
          component={ApproveTestimony}
        />
        <Route path="/signup" component={Signnin} />
      </Switch>
      <Footer />
    </div>
  </Suspense>
);

export default Main;
