const initState = {
    products: [],
    product: {},
    isLoading: false
}

const productsReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SHOW_ALL_PRODUCTS':
            return ({
                ...state,
                products: action.payload,
                isLoading: false
            })
        case 'SHOW_PRODUCT':
            return ({
                ...state,
                product: action.payload,
                isLoading: false
            })
        default:
            return state
    }
}

export default productsReducer