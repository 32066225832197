import React from "react";
import { NavLink } from "react-router-dom";
import PageHeader from "../../PageHeader/PageHeader";

const SingleArticle = (props) => {
  return (
    <div className="blog-wrapper">
      <NavLink
        className="single-article-wrapper"
        style={{ textDecoration: "none", color: "black" }}
        to={`/article/${props.data._id}`}
      >
        <img src={props.data.image} alt="" className="article-image" />
        <div className="article-text">
          <div
          // style={{ display: 'flex', flexDirection: "row" }}
          >
            <p className="article-author">{props.data.author}</p>
            <p className="article-date">{props.data.createdon}</p>
            <h1 className="article-title">{props.data.title}</h1>
          </div>
          {/* <p className="article-body">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                  soluta officia, aspernatur quam nisi error laboriosam quaerat
                </p> */}
        </div>
      </NavLink>
    </div>
  );
};

export default SingleArticle;
