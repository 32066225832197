import React from "react";
import "./PageHeader.css";

const PageHeader = (props) => {
  return (
    <div
      className={props.longHeader ? "page-header header-long" : "page-header"}
      {...props}
    >
      <span>{props.title}</span>
    </div>
  );
};

export default PageHeader;
