import React, { Component } from "react";
import axios from "axios";
import { SERVER_URL } from "../../../globals";
import { NavLink } from "react-router-dom";
import "./Article.css";
import logo from "../../../assets/LOGO.png";
import Spinner from "react-bootstrap/Spinner";
import * as firebase from "firebase/app";
import SingleArticle from "./SingleArticle";

export default class NRICorner extends Component {
  state = {
    articles: [],
    loading: true,
  };

  articleURL = `${SERVER_URL}/blog`;

  componentWillMount() {
    this.fetchData();
    const analytics = firebase.analytics();
    analytics.logEvent("NRI Corner");
  }

  async fetchData() {
    try {
      const response = await axios.post(this.articleURL, {
        type: "NRI Corner",
      });
      this.setState({ articles: response.data, loading: false });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "whitesmoke",
          }}
        >
          <div className="article-wrapper">
            {this.state.articles.length > 0 ? (
              this.state.articles.map((article) => {
                return <SingleArticle data={article} key={article._id} />;
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "whitesmoke",
                  minHeight: "85vh",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={logo} alt="" style={{ height: 135, width: 270 }} />
                <div>More Content Coming Soon! Stay Tuned To This Page</div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
