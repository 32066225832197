import React, { useEffect } from "react";
import "./App.css";
import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Provider } from "react-redux";
import store from "./store";
import Main from "./components/routes";
import { BrowserRouter } from "react-router-dom";
import * as firebase from "firebase";
import { Modal, Button } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export { store };

const firebaseConfig = {
  apiKey: "AIzaSyCRbhcOMlx7h_0uTtoc44fPZpjwUX5opRs",
  authDomain: "drops2ocean.firebaseapp.com",
  databaseURL: "https://drops2ocean.firebaseio.com",
  projectId: "drops2ocean",
  storageBucket: "drops2ocean.appspot.com",
  messagingSenderId: "990368889996",
  appId: "1:990368889996:web:8218219658ffab7b044fa9",
  measurementId: "G-JMD2WFPYC6",
};

function App() {
  const [modalShow, setModalShow] = React.useState(true);
  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
