const initState = {
    notices: []
}

const productsReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default productsReducer