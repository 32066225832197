import { combineReducers } from "redux";
import articleReducer from './articles.reducer'
import productReducer from './products.reducer'
import testimonialReducer from './testimonials.reducer'
import noticeReducer from './notice.reducer'

const rootReducer = combineReducers({
    articles: articleReducer,
    products: productReducer,
    testimonials: testimonialReducer,
    notices: noticeReducer
});
export default rootReducer;
